<template>
    <v-main>
        <div class="pt-9 pb-9 pr-9 pl-9">
            <v-container class="mb-12">
                <v-row>
                    <Tabs :showCounter="true" ref="tabEl" :tabs="tabs" :allowUserChange="false" />
                </v-row>

                <v-row class="mt-16">
                    <v-main>
                        <Simulacao @changeData="changeData" @onReset="onReset" />
                        <v-row justify="space-between">
                            <v-col md="6" sm="12" cols="12" lg="6">
                                <MainFilter
                                    @onInputChange="filterInputChanged"
                                    @onClick="mostrarFiltro"
                                />
                            </v-col>
                            <v-col md="6" sm="12" cols="12" lg="6" align="right">
                                <v-btn
                                    depressed
                                    class="smartSelectionButton white--text"
                                    color="secondary"
                                    x-large
                                    @click="toggleSelecaoSmart"
                                    title="Seleção Smart"
                                >
                                    <v-icon class="mr-2 icon-smart">mdi-currency-usd</v-icon>

                                    <span class="white--text text-none mr-1">Seleção</span
                                    ><span>Smart</span>
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-show="filter">
                            <Filters @onApply="searchOriginacoesListener" />
                        </v-row>

                        <div class="pt-10">
                            <v-data-table
                                :headers="headers"
                                :items="originacoes"
                                hide-default-footer
                                show-select
                                @item-selected="onSelect"
                                @click:row="onClickRow"
                                v-model="selected"
                                class="elevation-0 pb-4 flat-container"
                                id="table-meus-clients-consulta"
                            >
                                <template v-slot:[`header.data-table-select`]="{}"></template>

                                <template v-slot:[`item.registry`]="{ item }">
                                    <v-chip
                                        small
                                        :class="`status-chip ${getCadastro(item.registry).color}`"
                                    >
                                        {{ getCadastro(item.registry).label }}
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.credit`]="{ item }">
                                    <v-chip
                                        small
                                        :class="`status-chip ${getCredito(item.credit).color}`"
                                    >
                                        {{ getCredito(item.credit).label }}
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.available`]="{ item }">
                                    {{ formatarDinheiro(item.available) }}
                                </template>

                                <template v-slot:[`item.simulated`]="{ item }">
                                    {{ formatarDinheiro(item.simulated) }}
                                </template>

                                <template v-slot:[`item.total`]="{ item }">
                                    {{ formatarDinheiro(item.total) }}
                                </template>

                                <template v-slot:footer>
                                    <v-pagination
                                        color="secondary"
                                        v-model="currentPage"
                                        class="mt-3"
                                        :total-visible="10"
                                        :length="originacoesPages"
                                    ></v-pagination>
                                </template>
                            </v-data-table>
                        </div>
                        <Loader
                            class="text-center"
                            v-if="originacoesRequestStatus === RequestStatusEnum.START"
                        />
                    </v-main>
                </v-row>
                <v-row justify="end">
                    <v-col md="6" sm="12" cols="12" lg="6" align="right" class="pr-0 mt-3">
                        <v-btn
                            depressed
                            class="white--text"
                            color="secondary"
                            :disabled="
                                notas.length === 0 ||
                                selecaoManualRequestStatus === RequestStatusEnum.START
                            "
                            @click="onSelecaoManual"
                            >Finalizar Seleção</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-dialog v-model="dialog" persistent width="350" content-class="modal">
            <v-card>
                <v-card-title class="modal-header">
                    Nova Originação
                    <v-icon role="button" title="Fechar" @click="$router.back()" color="altPrimary">
                        mdi-close-thick</v-icon
                    >
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row class="pa-3">
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                            <strong>Moeda</strong>
                        </v-col>
                        <v-col lg="6">
                            <v-main>
                                <v-card
                                    @click="optionsNovaOriginacao('currency', 0)"
                                    class="pa-4 d-flex flex-column text-center flat-container"
                                    max-width="150"
                                    elevation="0"
                                    :style="getCardColor('currency', 0)"
                                >
                                    <v-icon>mdi-currency-brl</v-icon>
                                    <span>Real</span>
                                </v-card>
                            </v-main>
                        </v-col>
                        <v-col lg="6">
                            <v-main>
                                <v-card
                                    @click="optionsNovaOriginacao('currency', 1)"
                                    class="pa-4 d-flex flex-column text-center flat-container"
                                    max-width="150"
                                    elevation="0"
                                    :style="getCardColor('currency', 1)"
                                >
                                    <v-icon>mdi-currency-usd</v-icon>
                                    <span>Dólar</span>
                                </v-card>
                            </v-main>
                        </v-col>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                            <strong>Tipo</strong>
                        </v-col>
                        <v-col lg="6">
                            <v-main>
                                <v-card
                                    @click="optionsNovaOriginacao('type', 0)"
                                    class="pa-4 d-flex flex-column text-center flat-container"
                                    max-width="150"
                                    elevation="0"
                                    :style="getCardColor('type', 0)"
                                >
                                    <v-icon>mdi-note-multiple-outline</v-icon>
                                    <span>Nota</span>
                                </v-card>
                            </v-main>
                        </v-col>
                        <v-col lg="6">
                            <v-main>
                                <v-card
                                    @click="optionsNovaOriginacao('type', 1)"
                                    class="pa-4 d-flex flex-column text-center flat-container"
                                    max-width="150"
                                    elevation="0"
                                    :style="getCardColor('type', 1)"
                                >
                                    <v-icon>mdi-note-text-outline</v-icon>
                                    <span>Pedido</span>
                                </v-card>
                            </v-main>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-row>
                        <v-col xs="12" lg="12" class="d-flex justify-end">
                            <v-btn
                                :disabled="config.type === null || config.curency === null"
                                depressed
                                class="mt-4 mb-4"
                                color="secondary"
                                @click="closedModal"
                            >
                                <span class="white--text text-none">Continuar</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ModalManual
            v-if="showModal"
            @onSave="saveNotas"
            @onClose="onClose"
            :modal="showModal"
            :totalNotas="notas"
            :selected="lastSelected"
        />
        <SelecaoSmart
            :modal="showSelecao"
            :config="config"
            @onClose="toggleSelecaoSmart"
            v-if="showSelecao"
        />

        <div>
            <v-main :class="`mt-4 text-center buttonStyleFixedBar ${!isVisible ? 'down' : ''}`">
                <v-icon v-if="isVisible" color="#fff" @click="toggleVisibility"
                    >mdi-chevron-down</v-icon
                >
                <v-icon v-else color="#fff" @click="toggleVisibility">mdi-chevron-up</v-icon>
            </v-main>

            <section
                v-bind:class="{ 'container-footer-visible': isVisible }"
                class="container-footer"
            >
                <Footer :notas="notas" :statusFromSimulation="statusFromSimulation" :dateFromSimulation="dateFromSimulation" />
            </section>
        </div>
        <Loader v-if="selecaoManualRequestStatus === RequestStatusEnum.START" mode="overlay" />
    </v-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Filters from './_Filters';
import ModalManual from './ModalManual';
import SelecaoSmart from './SelecaoSmart';
import Footer from './_footer';
import Tabs from '@/components/base/Tabs';
import Loader from '@/components/base/Loader';
import MainFilter from '@/components/base/mainfilter/MainFilter';
import Simulacao from './Simulacao';
import { CreditStatusColors, RegistersStatus, RequestStatusEnum } from '@/helpers/consts';
import { formatarDinheiro } from '@/helpers/formatarDinheiro';
import { headersSelecao, tabSelecao, tipoSelecao, tipoMoeda } from './consts';

export default {
    name: 'Consulta',
    components: {
        Tabs,
        MainFilter,
        Filters,
        Footer,
        ModalManual,
        Simulacao,
        SelecaoSmart,
        Loader,
    },
    data: () => ({
        loading: false,
        page: 0,
        filter: false,
        dateVencimento: null,
        dateEmissao: null,
        menuVencimento: false,
        menuInicio: false,
        filters: {},
        selected: [],
        currentTab: 'consultar',
        showModal: false,
        lastSelected: null,
        panel: false,
        dialog: true,
        isVisible: false,
        notas: [],
        showSelecao: false,
        config: {},
        type: null,
        currency: null,
        headers: headersSelecao,
        tabs: tabSelecao,
        tipoSelecao,
        tipoMoeda,
        RequestStatusEnum,
        statusFromSimulation: 'Atual',
        dateFromSimulation: null,
    }),
    computed: {
        ...mapGetters('originacao', {
            originacoes: 'originacoesList',
            originacoesPages: 'originacoesListTotalPages',
            originationId: 'originationId',
            selectedProduct: 'selectedProduct',
            originacoesRequestStatus: 'originacoesListRequestStatus',
            selecaoManualRequestStatus: 'selecaoManualRequestStatus',
        }),
        currentPage: {
            get() {
                return this.page + 1;
            },
            set(page) {
                this.page = page - 1;
                return page;
            },
        },
        routeType() {
            return this.$route.meta.type;
        },
    },
    watch: {
        page() {
            this.searchOriginacoes();
        },
        originationId(value) {
            if (value) {
                const path = this.routeType === 'origination' ? 'nova' : 'factoring';
                this.$router.push(`/admin/originacao/${path}/revisao/${value}`);
            }
        },
        currency(value) {
            this.config.currency = value;
        },
        type(value) {
            this.config.type = value;
        },
        showModal(value) {
            if (!value) {
                this.lastSelected = null;
            }
        },
        '$route.path': function (val, oldVal) {
            if (val !== oldVal) {
                this.reset();
            }
        },
    },
    methods: {
        ...mapActions('originacao', {
            fetchOriginacoes: 'fetchOriginacoes',
            selecaoManual: 'selecaoManual',
            resetState: 'resetState',
        }),
        toggleSelecaoSmart() {
            this.showSelecao = !this.showSelecao;
        },
        changeData(date) {
            this.statusFromSimulation = 'Simulado';
            this.isVisible = true;
            this.filters.vencimento_inicio = date;
            this.dateFromSimulation = date;
            this.searchOriginacoes();
        },
        onReset() {
            this.statusFromSimulation = 'Atual';
            this.dateFromSimulation = null;
        },
        toggleVisibility() {
            this.isVisible = !this.isVisible;
        },
        onSelect({ item, value }) {
            if (value) {
                item.selected = item.notas.length;
                this.notas = [...this.notas, ...item.notas];
                this.isVisible = true;
                return;
            }
            item.selected = 0;
            this.notas = this.notas.filter(el => el.parentId !== item.id);
        },
        onClickRow(item) {
            this.showModal = true;
            this.lastSelected = item;
        },
        onClose(select) {
            this.selected = this.selected.filter(el => el.id !== select.id);
            this.notas = this.notas.filter(el => el.parentId !== select.id);
            this.showModal = false;
        },
        saveNotas({ notas, selected }) {
            selected.selected = notas.length;
            this.selected = [...this.selected, selected];
            this.notas = [...this.notas, ...notas].reduce((unique, el) => {
                return unique.includes(el) ? unique : [...unique, el];
            }, []);
            this.showModal = false;
        },
        closedModal() {
            this.dialog = false;
            this.searchOriginacoes();
        },
        mostrarFiltro() {
            this.filter = !this.filter;
        },
        searchOriginacoesListener(data) {
            this.filters = { ...data };
            this.searchOriginacoes();
        },
        getCardColor(key, value) {
            return this[key] === value ? 'background: #DDF2EF' : 'background: #FFF';
        },
        async optionsNovaOriginacao(key, value) {
            this[key] = value;
        },
        formatarDinheiro,
        async searchOriginacoes() {
            let filters = {
                ...this.filters,
                page: this.page,
                limit: 10,
            };
            const payload = {
                id_produto: this.selectedProduct.id,
                tipo: this.config.type,
                filters,
            };
            await this.fetchOriginacoes(payload);
        },
        async onSelecaoManual() {
            const payload = {
                id_produto: this.selectedProduct.id,
                tipoSelecao: this.config.type,
                tipoMoeda: this.config.currency,
                tipoOperacao: 1,
                data: new Date().toISOString(),
                idsNotas: this.notas.map(el => el.id),
            };
            await this.selecaoManual(payload);
        },
        getCadastro(key) {
            return RegistersStatus[parseInt(key, 10)];
        },
        getCredito(key) {
            return CreditStatusColors[parseInt(key, 10)];
        },
        filterInputChanged(value) {
            this.filters.nomeOuDocumento = value;
            if (value.length >= 3 || value === '') {
                this.searchOriginacoes();
            }
        },
        reset() {
            this.resetState();
            this.config = {
                currency: 0,
                type: 0,
            };
            this.initiate();
        },
        initiate() {
            if (this.routeType === 'factoring') {
                this.searchOriginacoes();
            }
            this.dialog = this.routeType === 'origination';
        },
    },
    created() {
        this.config = { ...this.config, ...this.$route.meta.config };
        this.initiate();
    },
};
</script>
<style scoped lang="scss">
@import './Selecao.scss';
</style>
<style>
.modal-header {
    justify-content: space-between;
}
.modal {
    overflow: hidden;
}
</style>
