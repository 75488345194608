<template>
    <v-row class="d-flex align-center">
        <v-col lg="3" class="firstWidgetNotas">
            <v-main>{{ notas.length }}</v-main>
            <v-main>Notas</v-main>
            <v-main>Selecionadas</v-main>
        </v-col>
        <v-col lg="3" class="secoundWidgetNotas">
            <div class="d-flex mb-2">
                <v-icon class="iconCenario">mdi-monitor</v-icon>
                <v-main class="pl-2 pb-2 pt-1 white--text"
                    >Cenário {{ formatDate(dateFromSimulation) }}</v-main
                >
            </div>
            <v-select
                class="scenario-select"
                background-color="#fff"
                :items="items"
                v-model="item"
                height="30"
                solo
                label="Cenário"
            ></v-select>
        </v-col>
        <v-col lg="6" class="threeWidgetNotasBack">
            <div class="threeWidgetNotas">
                <div class="d-flex">
                    <img
                        class="iconValorSelecionado"
                        src="@/assets/iconFarm/icon-Valor-Selecionado.svg"
                        alt="valorSelecionado"
                    />
                    <div class="mr-10 ml-5 pt-3">
                        <v-main class="white--text"> {{ formatarDinheiro(selectionValue) }}</v-main>
                        <p class="textValorSelecionado">Valor Selecionado</p>
                    </div>
                    <img
                        class="iconValorDisponivel"
                        src="@/assets/iconFarm/icon-Farm.svg"
                        alt="valorDisponivel"
                    />
                    <div v-if="availability">
                        <v-main class="white--text pt-3">
                            {{ formatarDinheiro(availability.disponivel) }}
                        </v-main>
                        <p class="textValorSelecionado">
                            Disponível em {{ formatDate(availability.data) }}
                        </p>
                    </div>
                </div>
                <div class="styleProgressBar">
                    <v-progress-linear
                        background-color="white"
                        color="teal accent-2"
                        :value="calculatePercentage"
                    >
                    </v-progress-linear>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import { formatDate } from '@/helpers/data';
import { formatarDinheiro } from '@/helpers/formatarDinheiro';
import { mapGetters } from 'vuex';
export default {
    props: {
        notas: {
            type: Array,
            default: () => [],
        },
        statusFromSimulation: {
            type: String,
            default: 'Atual',
        },
        dateFromSimulation: {
            type: String,
            default: null,
        },
    },
    data: () => {
        return {
            items: ['Atual'],
            item: 'Atual',
            formatDate,
            formatarDinheiro,
            availability: null,
        };
    },
    watch: {
        availabilityDate(newValue) {
            this.availability = newValue;
        },
        availabilityToday(newValue) {
            this.availability = newValue;
        },
        statusFromSimulation(newValue) {
            this.item = newValue;
            if (newValue === 'Atual') {
                this.items = ['Atual'];
                this.availability = this.availabilityToday;
            } else {
                this.items = ['Atual', 'Simulado'];
            }
        },
    },
    computed: {
        ...mapGetters('originacao', {
            availabilityDate: 'availabilityDate',
            availabilityToday: 'availabilityToday',
        }),
        selectionValue() {
            return this.notas.reduce((acc, el) => acc + el.value, 0);
        },
        calculatePercentage() {
            return this.availability
                ? (this.selectionValue / this.availability.disponivel) * 100
                : 0;
        },
    },
};
</script>
<style lang="scss" scoped>
@import './_footer.scss';
</style>
<style lang="scss">
.v-text-field.v-text-field--solo .v-input__control {
    min-height: 30px;
}
</style>
