<template>
    <v-dialog content-class="modal-default" persistent v-model="showModal" width="400">
        <v-card outlined class="card-border">
            <v-card-title class="modal-header">
                Seleção Smart
                <v-icon role="button" title="Fechar" @click="closeModal" color="altPrimary"
                    >mdi-close-thick</v-icon
                ></v-card-title
            >

            <v-card-text>
                <v-form v-model="valid" class="form-custom-disabled">
                    <v-row>
                        <v-col md="8">
                            Simulado
                        </v-col>
                        <v-col lg="6">
                            <v-card
                                class="pa-4 d-flex flex-column text-center"
                                max-width="150"
                                :style="getCardColor('Atual')"
                                elevation="1"
                            >
                                <span>Atual</span>
                            </v-card>
                        </v-col>
                        <v-col lg="6">
                            <v-card
                                class="pa-4 d-flex flex-column text-center"
                                max-width="150"
                                :style="getCardColor('Simulado')"
                                elevation="1"
                            >
                                <span>Simulado</span>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="10" lg="12">
                            <v-menu
                                ref="menuEmissao"
                                v-model="menuEmissao"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="form.startAt"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        class="v-input-default"
                                        color="primary"
                                        v-model="form.startAt"
                                        :rules="[rules.required]"
                                        label="Início relacionamento *"
                                        prepend-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-if="menuEmissao"
                                    v-model="date"
                                    no-title
                                    scrollable
                                    :min="minDate"
                                    locale="pt-br"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn color="secondary" depressed @click="menuEmissao = false"
                                        >Cancelar</v-btn
                                    >
                                    <v-btn
                                        color="secondary"
                                        depressed
                                        @click="$refs.menuEmissao.save(form.startAt)"
                                        >Salvar</v-btn
                                    >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col xs="12" lg="12" class="d-flex  justify-start">
                            <span
                                >A ferramenta de Seleção Smart vai selecionar Notas Fiscais de forma
                                automática e otimizada limitando-se a este valor:</span
                            >
                        </v-col>
                        <v-col md="8" lg="12">
                            <v-text-field
                                color="primary"
                                v-model="form.value"
                                label="Valor"
                                :rules="[rules.required]"
                                v-mask="currencyMask"
                                class="v-input-default"
                            ></v-text-field>
                        </v-col>

                        <v-col xs="12" lg="12" class="d-flex  justify-end">
                            <v-btn
                                depressed
                                class="mt-4 mb-4 white--text"
                                @click="onSubmit"
                                color="#00b493"
                            >
                                <span class="white--text text-none">Continuar</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { currency as currencyMask, currencyUnmask } from '@/helpers/masks';
import { formatarDataBarra } from '@/helpers/data';
export default {
    props: {
        modal: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        showModal: false,
        menuEmissao: false,
        form: {},
        currencyMask,
        valid: false,
        formatarDataBarra,
        date: new Date().toISOString().split('T')[0],
        minDate: new Date().toISOString().split('T')[0],
        rules: {
            required: value => !!value || 'Campo obrigatório',
        },
    }),
    watch: {
        date() {
            this.form.startAt = this.formatarDataBarra(this.date);
        },
    },
    computed: {
        ...mapGetters({
            selectedProduct: 'originacao/selectedProduct',
        }),
    },
    methods: {
        ...mapActions({
            selecaoSmart: 'originacao/selecaoSmart',
        }),
        closeModal() {
            this.showModal = false;
            this.$emit('onClose');
        },
        getCardColor(key) {
            const date = new Date().toISOString().split('T')[0];
            if (key === 'Atual') {
                return this.date === date ? 'background: #DDF2EF' : 'background: #FFF';
            }
            return this.date !== date ? 'background: #DDF2EF' : 'background: #FFF';
        },
        onSubmit() {
            const payload = {
                id_produto: this.selectedProduct.id,
                tipoSelecao: this.config.type,
                tipoMoeda: this.config.currency,
                tipoOperacao: this.config.operation,
                valorTeto: currencyUnmask(this.form.value),
                data: this.date,
            };
            this.selecaoSmart(payload);
        },
    },
    created() {
        this.form.startAt = this.formatarDataBarra(this.date);
        this.showModal = this.modal;
    },
};
</script>

<style>
.card-border {
    border: none !important;
}
.modal-header {
    justify-content: space-between;
}
</style>
