export const headersSelecao = [
    {
        text: 'Cliente',
        align: 'left',
        sortable: false,
        value: 'name',
        width: '20rem',
    },
    { text: 'Cadastro', align: 'center', value: 'registry', sortable: false },
    { text: 'Crédito', align: 'center', value: 'credit', sortable: false },
    { text: 'Notas', align: 'center', value: 'notasTotal', sortable: false },
    { text: 'Selecionadas', align: 'center', value: 'selected', sortable: false },
    { text: 'Limite Atual', align: 'center', value: 'available', sortable: false },
    {
        text: 'Limite Simulado',
        align: 'center',
        value: 'simulated',
        sortable: false,
    },
    { text: "Total NF'S", align: 'center', value: 'total', sortable: false },
];

export const tabSelecao = [
  {
      name: 'Seleção',
      path: '/admin/originacao/nova/selecao',
  },
  {
      name: 'Revisão',
      path: '/admin/originacao/nova/revisao',
  },
];

export const tipoSelecao = {
    NOTA: 0,
    PEDIDO: 1,
};

export const tipoMoeda = {
    BRL: 0,
    USD: 1,
};

export const headersModal = [
    {
        text: 'tipo',
        align: 'center',
        sortable: false,
        value: 'type',
    },
    { text: 'Número', align: 'center', value: 'number', sortable: false },
    { text: 'Emissão', align: 'center', value: 'issueDate', sortable: false },
    { text: 'Vencimento', align: 'center', value: 'dueDate', sortable: false },
    { text: 'Fornecedor', align: 'center', value: 'supplier', sortable: false },
    {
        text: 'Valor',
        align: 'center',
        value: 'valueFormated',
        sortable: false,
    },
];
