<template>
    <v-expansion-panels v-model="showPanel" flat class="mb-4 elevation-0 flat-container">
        <v-expansion-panel>
            <v-expansion-panel-header class="pl-2">
                <v-row>
                    <v-col md="9" sm="4" align-self="center">
                        <v-icon class="mr-3" color="secondary">mdi-chart-line</v-icon>
                        <span>Simulação de Cenário</span>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    <v-col lg="12" xl="12">
                        <v-simple-table class="border-table pa-3">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class=""></th>
                                        <th class="text-center">Disponibilidade</th>
                                        <th class="text-center">Carteira</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="availabilityToday">
                                        <td>
                                            <v-icon color="#00b493" class="mr-5">
                                                {{ todayInfo.icone }}
                                            </v-icon>
                                            <b>{{ todayInfo.titulo }}</b>
                                        </td>
                                        <td class="text-center">
                                            <v-chip color="#E5F7F4">
                                                <span class="font-verde">{{
                                                    todayInfo.disponivel
                                                }}</span>
                                            </v-chip>
                                        </td>
                                        <td class="text-center">
                                            <v-chip>
                                                <span class="font-verde">
                                                    {{ todayInfo.carteira }}
                                                </span>
                                            </v-chip>
                                        </td>
                                    </tr>
                                    <tr v-if="availabilityDate">
                                        <td>
                                            <v-icon color="#00b493" class="mr-5">
                                                {{ dateInfo.icone }}
                                            </v-icon>
                                            <b>{{ dateInfo.titulo }} {{ selectedDate }}</b>
                                        </td>
                                        <td class="text-center">
                                            <v-chip color="#E5F7F4">
                                                <span class="font-verde">{{
                                                    dateInfo.disponivel
                                                }}</span>
                                            </v-chip>
                                        </td>
                                        <td class="text-center">
                                            <v-chip>
                                                <span class="font-verde">
                                                    {{ dateInfo.carteira }}
                                                </span>
                                            </v-chip>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" xl="4" lg="4">
                        <v-layout>
                            <v-flex>
                                <v-menu
                                    ref="menuSimulacao"
                                    v-model="menuSimulacao"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="dataSimulacaoRange"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            class="v-input-default"
                                            color="secondary"
                                            v-model="dataSimulacaoRange"
                                            label="Data (Inicio/fim) emissão"
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-if="menuSimulacao"
                                        v-model="dataSimulacao"
                                        no-title
                                        range
                                        :min="date"
                                        scrollable
                                        locale="pt-br"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="secondary"
                                            depressed
                                            @click="menuSimulacao = false"
                                        >
                                            {{ $tc('defaults.form.cancel') }}
                                        </v-btn>
                                        <v-btn color="secondary" depressed @click="checkDateOrder">
                                            {{ $tc('defaults.form.save') }}
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-col>
                    <v-col>
                        <v-btn outlined depressed color="secondary" @click="reset">
                            {{ $tc('origination.simulation.resetToDefault') }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="mb-0 pl-6 pr-6">
                        <v-divider class="mb-6"></v-divider>
                        <span class="primary--text">{{
                            $tc('origination.simulation.helper')
                        }}</span>
                    </v-col>
                    <v-col v-if="simulacao.length > 0" lg="12" xl="12" cols="12" class="mt-2">
                        <div id="wrapper">
                            <div id="chart-area">
                                <apexchart
                                    flat
                                    type="area"
                                    height="350px"
                                    :options="chartOptionsArea"
                                    :series="seriesArea"
                                ></apexchart>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatDate } from '@/helpers/data';
import { formatarDinheiro } from '@/helpers/formatarDinheiro';
const { abbreviateNumber } = require('@/helpers/masks');

export default {
    data: () => ({
        dateGrafico: [],
        menuSimulacao: false,
        dataSimulacao: null,
        dataSimulacaoRange: null,
        showPanel: undefined,
        menuGrafico: false,
        filters: {},
        graphicData: [],
        selectedDate: null,
    }),
    computed: {
        ...mapGetters('originacao', {
            selectedProduct: 'selectedProduct',
            simulacao: 'simulacaoList',
            availabilityDate: 'availabilityDate',
            availabilityToday: 'availabilityToday',
        }),
        todayInfo() {
            return {
                titulo: 'Atual',
                icone: 'mdi-wallet-outline',
                disponivel: formatarDinheiro(this.availabilityToday.disponivel),
                carteira: formatarDinheiro(this.availabilityToday.pl),
            };
        },
        dateInfo() {
            return {
                titulo: 'Simulado',
                icone: 'mdi-calendar-text',
                disponivel: formatarDinheiro(this.availabilityDate.disponivel),
                carteira: formatarDinheiro(this.availabilityDate.pl),
            };
        },
        dates() {
            return this.simulacao.map(el => el.dataBr);
        },
        values() {
            return this.simulacao.map(el => el.valor);
        },
        seriesArea() {
            return [
                {
                    name: 'Valor',
                    data: this.values,
                },
            ];
        },
        chartOptionsArea() {
            return {
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    stroke: {
                        show: false,
                        colors: undefined,
                    },
                    events: {
                        markerClick: this.onClick,
                    },
                },
                colors: ['#00b493'],
                xaxis: {
                    categories: this.dates,
                },
                responsive: [
                    {
                        breakpoint: 1000,
                        options: {
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                },
                            },
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                ],

                dataLabels: {
                    enabled: true,
                    formatter: value => abbreviateNumber(value),
                },
                yaxis: {
                    labels: {
                        formatter: value => formatarDinheiro(value),
                    },
                },
            };
        },
        date() {
            return new Date().toISOString();
        },
    },
    methods: {
        ...mapActions('originacao', {
            fetchSimulacao: 'fetchSimulacao',
            getAvailability: 'getAvailability',
        }),
        checkDateOrder() {
            if (this.dataSimulacao.length > 1) {
                const dates = [new Date(this.dataSimulacao[0]), new Date(this.dataSimulacao[1])];
                if (dates[0] > dates[1]) {
                    this.dataSimulacao = [this.dataSimulacao[1], this.dataSimulacao[0]];
                }
            }

            if (this.$refs.menuSimulacao.save)
                this.$refs.menuSimulacao.save(
                    this.formatDateRange(this.dataSimulacao, 'dataSimulacao')
                );
        },
        onClick(_event, _context, { dataPointIndex }) {
            const date = this.simulacao[dataPointIndex].data;
            this.selectedDate = formatDate(date);
            this.searchAvalability(date);
            this.$emit('changeData', date);
        },
        searchSimulacao() {
            if (!this.filters.dataSimulacao || !this.filters.dataSimulacao_fim) {
                return null;
            }
            this.fetchSimulacao({
                data_simulacao_inicio: this.filters.dataSimulacao,
                data_simulacao_fim: this.filters.dataSimulacao_fim,
                id: this.selectedProduct.id,
            });
        },
        async searchAvalability(date, type = 'DATE') {
            const params = {
                id: this.selectedProduct.id,
                type,
                date,
            };
            await this.getAvailability(params);
        },
        formatDateRange(range, field) {
            this.filters[field] = range[0];
            if (range.length > 1) {
                this.filters[field + '_fim'] = range[1];
                this[field + 'Range'] =
                    formatDate(this.filters[field]) +
                    ' a ' +
                    formatDate(this.filters[field + '_fim']);
            } else {
                this[field + 'Range'] = formatDate(this.filters[field]);
                delete this.filters[field + '_fim'];
            }
            this.searchSimulacao();
            return this[field + 'Range'];
        },
        loadDefault() {
            let nextDate = new Date();
            nextDate.setDate(nextDate.getDate() + 30);
            this.dataSimulacao = [
                new Date().toISOString().split('T')[0],
                nextDate.toISOString().split('T')[0],
            ];
            this.formatDateRange(this.dataSimulacao, 'dataSimulacao');
            this.searchSimulacao();
        },
        async reset() {
            this.$emit('onReset');
            await this.searchAvalability(this.date, 'TODAY');
            this.loadDefault();
        },
    },
    async created() {
        await this.searchAvalability(this.date, 'TODAY');
        this.loadDefault();
    },
};
</script>
