<template>
    <v-main>
        <v-form ref="form" class="ml-3 mr-3">
            <v-row>
                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-text-field
                        label="Número"
                        placeholder="Número"
                        v-model="filters.number"
                        color="secondary"
                        class="v-input-filter"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-text-field
                        label="Fornecedor"
                        placeholder="Fornecedor"
                        v-model="filters.supplier"
                        color="secondary"
                        class="v-input-filter"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-3">
                    <v-btn depressed @click="apply" color="secondary" class="v-btn-padded">
                        <span class="text-none">Aplicar</span>
                    </v-btn>

                    <v-btn
                        outlined
                        @click="onFilterClear"
                        depressed
                        color="secondary"
                        class="ml-4 v-btn-padded"
                    >
                        <span class="text-none">Limpar</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        filters: {},
    }),

    methods: {
        async apply() {
            this.$emit('onApply', { ...this.filters });
        },
        onFilterApply() {
            return true;
        },
        onFilterClear() {
            this.filters = {};
             this.$emit('onApply', { ...this.filters });
        },
        ...mapActions('originacao', {
            getSegments: 'getSegments',
            getStates: 'getStates',
            listUsers: 'listUsers',
        }),
    },
};
</script>
